import "./whatsapp.css";

import whatsapp from '../assets/whatsapp.png';


const Whatsapp = () => {
    return (
        <>
        <a href='https://api.whatsapp.com/send?phone=541127017384&text=Hola%20A%20Tu%20Medida!...' className="float" target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt="logo-whatsapp"/></a>
        </>
    );
}

export default Whatsapp;