import "./mediosDePago.css";

import mercadopago from '../assets/mercadopago.png';
import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import americanexpress from '../assets/americanexpress.png'


const MediosDePago = () => {
    return (
        <div className="container col-10 col-md-8 col-xl-8 mediosdepago-style medios-mt">
            <h2 className="mediosdepago-title text-center my-5">¿Cuáles son nuestros medios de pago?</h2>
            <div className="row d-md-flex d-xl-flex justify-content-center my-5 medios-mt medios-center pr-medios no-row">
                <div className="col-12 col-md-3 col-xl-2 logo-mercadopago mb-3 no-row">
                    <img src={mercadopago} alt="logo-mercadopago"></img>
                </div>
                <div className="col-12 col-md-3 col-xl-2 logo-visa mb-3 no-row">
                    <img src={visa} alt='logo-visa'></img>
                </div>
                <div className="col-12 col-md-3 col-xl-2 logo-mastercard mb-3 no-row">
                    <img src={mastercard} alt='logo-mastercard'></img>
                </div>
                <div className="col-12 col-md-3 col-xl-2 logo-americanexpress mb-3 no-row">
                    <img src={americanexpress} alt='logo-americanexpress'></img>
                </div>
            </div>
            <div className="container d-flex justify-content-center mt-5">
                <div className="col-10 pagos-text" >
                    <p>*Puede abonar nuestros productos con el método de Mercado Pago aprovechando las promociones de hasta 12 cuotas sin Interés (según disponibilidad al momento de efectuar la compra).</p>
                </div>
            </div>
        </div>
    );
}

export default MediosDePago;