import { Col, Row } from 'react-bootstrap';
import nosotros1 from '../assets/nosotros/nosotros1.png';
import nosotros2 from '../assets/nosotros/nosotros2.png';
import nosotros3 from '../assets/nosotros/nosotros3.png';
import './Nosotros.css'
const Nosotros = () => {
    return (
        <div className="container" translate='no'>
            <Row className='d-flex'>
                <Col xs={11} md={12} lg={6} xl={6} className="d-flex flex-wrap container-nosotros">
                    <p className='title-nosotros'>Conocé mejor cuál es la esencia qué nos define como empresa...</p>
                    <div className='line-nosotros'></div>
                    <div className='texto-nosotros fw-light'>
                        <p>Somos una empresa familiar dedicada a la producción y fabricación de muebles de diseño acorde a los diferentes tipos de necesidades de los clientes.</p>
                        <p>Todos nuestros diseños tienen como objetivo realzar la decoración interior y dar un sello de originalidad y comodidad adaptable a diferentes estilos de vida.</p>
                        <p>Brindamos el asesoramiento necesario para transformar los lugares en el espacio de diseño adecuado para el cliente</p>
                    </div>
                    <div className='mt-0 fw-light'>
                        <p className='spicing firma-nosotros'>GRUPO MELART</p>
                        <p className='spicing firma-nosotros'>AMOBLAMIENTOS A TU MEDIDA</p>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={6}  className='img-nosotros text-sm-center text-md-center text-lg-end text-xl-end'>
                    <img className="img-style" src={nosotros1} alt="nosotros1" />
                    <img className="img-style" src={nosotros2} alt="nosotros2" />
                    <img className="img-style" src={nosotros3} alt="nosotros3" />
                </Col>
            </Row>
        </div>
    )
}

export default Nosotros;