import "./portada.css";

import portada1 from '../assets/portada-cocina1.png'
import portada2 from '../assets/portada-cocina2.png'
import portada3 from '../assets/portada-cocina3.png'

import portada4 from '../assets/portada-vestidor1.png'
import portada5 from '../assets/portada-vestidor2.png'
import portada6 from '../assets/portada-vestidor3.png'

const Portada = () => {
    return (
      <div className="container my-5 ">
        <div className="row justify-content-center">
        <h2 className="portada-title col-10 mb-4 seccion-separador">COCINAS</h2>
          <div className="col-6">
          <img src={portada1} alt='portada' className="portada-img mb-0 mb-md-4 mb-lg-5 mb-xl-5"/>
          <img src={portada3} alt='portada' className="portada-img2 mt-4 mt-md-0 mt-xl-0"/>
          </div>
          <div className="col-4">
          <img src={portada2} alt='portada' className="portada-img1"/>
          </div>
        </div>

        <div className="row justify-content-center mt-2 mt-md-3 mt-xl-5 pt-3 pt-md-3 pt-xl-5">
        <h2 className="portada-title col-10 mt-5 mb-4 text-end">VESTIDORES</h2>
          <div className="col-4">
          <img src={portada4} alt='portada' className=""/>
          </div>
          <div className="col-6 ms-0 ms-md-1 ms-xl-2">
          <img src={portada6} alt='portada' className="portada-img mb-4 mb-md-4 mb-lg-5 mb-xl-5 ms-md-4"/>
          <img src={portada5} alt='portada' className="portada-img2 mt-md-2 ms-md-4"/>
          </div>
        </div>
      </div>
    );
  }
  
  export default Portada;
  