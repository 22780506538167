import "./footer.css";

import facebook from '../assets/facebook.svg'
import instagram from '../assets/instagram.svg'

const Footer = () => {
  return (
    <div className="container col-8 text-center footer-layout mt-footer" translate='no'>
      <div className="col-12 footer-title">
        <p className="pt-5 footer-font">¡seguinos en redes!</p>
      </div>
      <div className="d-flex justify-content-center pt-3 pb-5">
      <a href='https://www.facebook.com/atumedidaamoblamientos' target="_blank" rel="noopener noreferrer" className="col-1 footer-align-end">
        <img src={facebook} alt="facebook icon" className="footer-facebook-icon w-75 text-end"></img>
      </a>
      <a href='https://www.instagram.com/atumedida.com.ar' target="_blank" rel="noopener noreferrer" className="col-1 footer-align-start">
      <img src={instagram} alt="facebook icon" className="footer-facebook-icon w-75 text-start"></img>
      </a>
      </div>
      <div className="section">
      <div className="col-12 footer-title">
        <p className="pb-2 higher-font higher-not-mobile">© 2024 | by Higher Web</p>
        <p className="pb-2 higher-font higher-not-desktop">© 2024 | Desarrollado by Higher Web</p>
      </div>
      </div>
    </div>
  );
}

export default Footer;
