import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import './navigationBar.css';

function NavigationBar() {
    const collapseRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (collapseRef.current && !collapseRef.current.contains(event.target)) {
                if (collapseRef.current.classList.contains('show')) {
                    collapseRef.current.classList.remove('show');
                }
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleNavClose = () => {
        if (collapseRef.current && collapseRef.current.classList.contains('show')) {
            collapseRef.current.classList.remove('show');
        }
        window.scrollTo(0, 0);
    };

    return (
        <div className='navbar-fixed'>
            <Navbar expand="xl" bg="transparent">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">
                        <img
                            src={process.env.PUBLIC_URL + '/logo/logo.png'}  // Ruta relativa a la carpeta src
                            width="30"
                            height="30"
                            className="d-inline-block align-top logo-style ms-2 ps-2 nav-logo-lg"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse ref={collapseRef} id="basic-navbar-nav" className='text-navcenter mx-lg-4' translate='no'>
                        <Nav className="navbar-nav d-flex align-items-center">
                            <NavDropdown className=' mx-1 mx-xxl-2 custom-dropdow' id="dropdown-item-button" title="Cocinas">
                                <DropdownButton className='custom-dropdow' id="dropdown-item-button" title="Laqueados" drop='end'>
                                    <Dropdown.Item as={Link} to="/productos/cocina/laqueados/brillantes" onClick={handleNavClose}>Brillantes</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/productos/cocina/laqueados/satinados" onClick={handleNavClose}>Satinados</Dropdown.Item>
                                </DropdownButton>
                                <Dropdown.Item as={Link} to="/productos/cocina/melamina" onClick={handleNavClose}>Melamina</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/productos/cocina/vintage" onClick={handleNavClose}>Vintage</Dropdown.Item>
                            </NavDropdown>
                            <Nav.Link className='mx-1 mx-xxl-2' id="dropdown-item-button" as={Link} to="/productos/placares" onClick={handleNavClose}>Placares</Nav.Link>
                            <Nav.Link className='mx-1 mx-xxl-2' id="dropdown-item-button" as={Link} to="/productos/vestidores" onClick={handleNavClose}>Vestidores</Nav.Link>
                            {/* Submenú para Muebles */}
                            <NavDropdown className='mx-1 mx-xxl-2 custom-dropdow' id="dropdown-item-button" title="+ Muebles">
                                <Dropdown.Item as={Link} to="/productos/muebles/escritorios" onClick={handleNavClose}>Escritorios</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/productos/muebles/tv" onClick={handleNavClose}>Tv</Dropdown.Item>
                            </NavDropdown>
                            <NavDropdown className='mx-1 mx-xxl-2 custom-dropdow' id="dropdown-item-button" title="Herrajes por mayor">
                                <Dropdown.Item as={Link} to="/productos/herrajes/correderas" onClick={handleNavClose}>Correderas de cajones</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/productos/herrajes/bisagras" onClick={handleNavClose}>Bisagras</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/productos/herrajes/kitPuertas" onClick={handleNavClose}>Kit de Puertas Corredizas</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/productos/herrajes/tapaCantos" onClick={handleNavClose}>Tapa cantos PVC y ABS</Dropdown.Item>
                            </NavDropdown>
                            <Nav.Link className='mx-1 mx-xxl-2' as={Link} to="/nosotros" onClick={handleNavClose}>Nosotros</Nav.Link>
                            <Nav.Link className='mx-1 mx-xxl-2' as={Link} to="/contact" onClick={handleNavClose}>Contacto</Nav.Link>
                            <div className='d-flex'>
                                <a href='https://www.facebook.com/atumedidaamoblamientos' target='blank'><FontAwesomeIcon className='d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none facebook-icon-mobile' icon={faFacebook} /></a>
                                <a href='https://www.instagram.com/atumedida.com.ar' target='blank'><FontAwesomeIcon className='d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none instagram-icon-mobile' icon={faInstagram} /></a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='nav-icon me-4'>
                        <a
                            href="https://www.facebook.com/atumedidaamoblamientos"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-social btn-facebook"
                        >
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a
                            href="https://www.instagram.com/atumedida.com.ar"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-social btn-instagram"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavigationBar;