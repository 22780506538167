import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ProductCategoryTitle from '../utils/ProductCategoryTitle';
import ProductImages from '../utils/ProductImages';
import marcasImage from '../assets/marcas.png';

import './ProductCategory.css'
const ProductCategory = () => {
  const { category, subcategory, subsubcategory } = useParams();
  let categoryString = `productos | ${category}`;
  if (subcategory) {
    categoryString += ` | ${subcategory}`;

    if (subsubcategory) {
      categoryString += ` | ${subsubcategory}`;
    }
  }
  const words = categoryString.split(' ');
  const title = ProductCategoryTitle[words[words.length - 1]]
  const images = ProductImages[words[words.length - 1]];
  return (
    <div className='container container-products' translate='no'>
      <p className="categorys category-text">
        {words.map((word, index) => (
          <React.Fragment key={index}>
            {index === words.length - 1 ? <b>{word}</b> : word}{' '}
          </React.Fragment>
        ))}
      </p>
      <p className='title'>{title}</p>
      {images && images.length > 0 && (
        <Row className='mt-0 mt-md-4 mt-lg-5 row-not'>
          {images.map((image, index) => (
            <Col key={index} xs={12} md={6} className='img-product'>
              <img src={image} alt={`Product ${index + 1}`} className='img-fluid mt-3' />
            </Col>
          ))}
        </Row>
      )}
      <div className="container description mt-5 mb-sm-2 mb-md-5 mb-xl-5">
        <Row className='justify-content-sm-start justify-content-md-start justify-content-lg-center justify-content-xl-center'>
          <Col className='mt-5 mb-sm-2 mb-md-5 mb-xl-5 m-leftproduct' xs={12} md={6}>
            <p className='description-title ms-4 ms-md-5 ms-xl-3'>COMPOSICIÓN</p>
            <ul className='text-left ms-2 ms-md-5 ms-xl-0'>
              <li className='description-text'>Melamina 18 mm. Primeras marcas</li>
              <li className='description-text'>Fibroplus 3 mm.</li>
              <li className='description-text'>Canto PVC 045 (no papel).</li>
              <li className='description-text'>Corredores metálicos, solicitar variante.</li>
              <li className='description-text'>Tiradores metálicos y aluminio.</li>
            </ul>
          </Col>
          <Col className='my-5' xs={12} md={4}>
            <p className='description-title text-right ms-4 ms-md-5 ms-xl-0'>CARACTERÍSTICAS</p>
            <p className='description-text text-right ms-4 ms-md-5 ms-xl-0'>
              Cada diseño se encuentra acorde a las distintas necesidades de nuestros clientes,
              brindando variedad y calidad para todo tipo de modelos.
            </p>
          </Col>
        </Row>
      </div>
      <div className="container-img">
        <Row className='my-3 d-flex flex-row-reverse'>
          <Col xs={12} md={12}>
            <img className="img-style-product" src={marcasImage} alt="Marcas" />
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ProductCategory;
