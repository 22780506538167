import './hero.css'


function Hero(props) {
    return (
        <>
            <div className={props.cName}>
                <img src={props.heroImg} alt='portada' className='hero' />

                <div>
                    <p className='col-10 col-md-8 col-xl-7 hero-text'>{props.text} </p>
                </div>
            </div>
        </>
    );
}

export default Hero;
