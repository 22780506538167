import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Swal from 'sweetalert2'

const root = ReactDOM.createRoot(document.getElementById('root'));

const urlParams = new URLSearchParams(window.location.search);
  const isEmailSend = urlParams.get('email');
  if (isEmailSend) {
    Swal.fire({
      // title: '¡Gracias por contactarnos!',
      text: '¡Gracias por contactarnos! Nos comunicaremos pronto',
      icon: 'success',
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#121111',
      // titleTextColor: 'red'
    })
  } else {

  }

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
