//laqueados brillantes
import laqueadosBrillantes1 from '../assets/laqueados-brillante/laqueado-brillante1.png';
import laqueadosBrillantes2 from '../assets/laqueados-brillante/laqueado-brillante2.png';
import laqueadosBrillantes3 from '../assets/laqueados-brillante/laqueado-brillante3.png';
import laqueadosBrillantes4 from '../assets/laqueados-brillante/laqueado-brillante4.png';
import laqueadosBrillantes5 from '../assets/laqueados-brillante/laqueado-brillante5.png';
import laqueadosBrillantes6 from '../assets/laqueados-brillante/laqueado-brillante6.png';
//laqueados satinados
import laqueadosSatinados1 from '../assets/laqueados-satinado/laqueado-satinado1.png';
import laqueadosSatinados2 from '../assets/laqueados-satinado/laqueado-satinado2.png';
import laqueadosSatinados3 from '../assets/laqueados-satinado/laqueado-satinado3.png';
import laqueadosSatinados4 from '../assets/laqueados-satinado/laqueado-satinado4.png';
import laqueadosSatinados5 from '../assets/laqueados-satinado/laqueado-satinado5.png';
import laqueadosSatinados6 from '../assets/laqueados-satinado/laqueado-satinado6.png';
//vintage
import vintage1 from '../assets/vintage/vintage1.png';
import vintage2 from '../assets/vintage/vintage2.png';
import vintage3 from '../assets/vintage/vintage3.png';
import vintage4 from '../assets/vintage/vintage4.png';
import vintage5 from '../assets/vintage/vintage5.png';
import vintage6 from '../assets/vintage/vintage6.png';
import vintage7 from '../assets/vintage/vintage7.png';
import vintage8 from '../assets/vintage/vintage8.png';
import vintage9 from '../assets/vintage/vintage9.png';
import vintage10 from '../assets/vintage/vintage10.png';
//melamina
import melamina1 from '../assets/melamina/melamina1.png';
import melamina2 from '../assets/melamina/melamina2.png';
import melamina3 from '../assets/melamina/melamina3.png';
// import melamina4 from '../assets/melamina/melamina4.png';
import melamina5 from '../assets/melamina/melamina5.png';
import melamina6 from '../assets/melamina/melamina6.png';
import melamina7 from '../assets/melamina/melamina7.png';
import melamina8 from '../assets/melamina/melamina8.png';
import melamina9 from '../assets/melamina/melamina9.png';
import melamina10 from '../assets/melamina/melamina10.png';
import melamina11 from '../assets/melamina/melamina11.png';
import melamina12 from '../assets/melamina/melamina12.png';
//placares
import placares1 from '../assets/placares/placares1.png';
import placares2 from '../assets/placares/placares2.png';
import placares3 from '../assets/placares/placares3.png';
import placares4 from '../assets/placares/placares4.png';
import placares5 from '../assets/placares/placares5.png';
import placares6 from '../assets/placares/placares6.png';
//vestidores
import vestidores1 from '../assets/vestidores/vestidores1.png';
import vestidores2 from '../assets/vestidores/vestidores2.png';
import vestidores3 from '../assets/vestidores/vestidores3.png';
import vestidores4 from '../assets/vestidores/vestidores4.png';
import vestidores5 from '../assets/vestidores/vestidores5.png';
import vestidores6 from '../assets/vestidores/vestidores6.png';
import vestidores7 from '../assets/vestidores/vestidores7.png';
import vestidores8 from '../assets/vestidores/vestidores8.png';
import vestidores9 from '../assets/vestidores/vestidores9.png';
import vestidores10 from '../assets/vestidores/vestidores10.png';
import vestidores11 from '../assets/vestidores/vestidores11.png';
//escritorios
import escritorios1 from '../assets/escritorios/escritorios1.png';
import escritorios2 from '../assets/escritorios/escritorios2.png';
import escritorios3 from '../assets/escritorios/escritorios3.png';
//tv
import tv1 from '../assets/tv/tv1.png';
import tv2 from '../assets/tv/tv2.png';
//bisagras
import bisagras from '../assets/bisagras/bisagras.png';
//kit-puertas
import kitPuertas from '../assets/kit-puertas/kit-puertas.png';
//correderas
import correderas from '../assets/correderas/correderas.png';
//tapa-cantos
import tapaCantos from '../assets/tapa-cantos/tapa-cantos.png';

const ProductImages = {
    'brillantes': [laqueadosBrillantes1, laqueadosBrillantes2, laqueadosBrillantes3, laqueadosBrillantes4, laqueadosBrillantes5, laqueadosBrillantes6],
    'satinados': [laqueadosSatinados1, laqueadosSatinados2, laqueadosSatinados3, laqueadosSatinados4, laqueadosSatinados5, laqueadosSatinados6],
    'vintage': [vintage1, vintage2, vintage3, vintage4, vintage5, vintage6, vintage7, vintage8, vintage9, vintage10],
    'melamina': [melamina1, melamina2, melamina3, melamina5, melamina6, melamina7, melamina8, melamina9, melamina10, melamina11, melamina12],
    'placares': [placares1, placares2, placares3, placares4, placares5, placares6],
    'vestidores': [vestidores1, vestidores2, vestidores3, vestidores4, vestidores5, vestidores6, vestidores7, vestidores8, vestidores9, vestidores10, vestidores11],
    'escritorios': [escritorios1, escritorios2, escritorios3],
    'tv': [tv1, tv2],
    'kitPuertas': [kitPuertas],
    'correderas': [correderas],
    'bisagras': [bisagras],
    'tapaCantos': [tapaCantos]
};

export default ProductImages;