import "./services.css";

function Services() {
  return (<>
    <div>
      <section>
        {/* <div className='container-fluid col-12 col-md-10 col-xl-10 d-md-flex d-xl-flex justify-content-center mt-5'>
            <div className='col-8 col-md-4 col-xl-3 col-3 services-text px-2'>
              <p>Trabajamos para individuos, constructoras, estudios y empresas</p>
            </div>
            <div className="1 d-none d-sm-none d-md-block d-xl-block">
            <div className="services-line"></div>
            </div>
            <div className='col-8 col-md-4 col-xl-3 services-text'>
              <p>Atendemos a nuestros clientes en forma personal y eficiente</p>
            </div>
            <div className="1 d-none d-sm-none d-md-block d-xl-block ">
            <div className="services-line"></div>
            </div>
            <div className='col-8 col-md-4 col-xl-3 services-text'>
              <p>Amoblamientos a medida y medidas estandar para mayoristas</p>
            </div>
          </div> */}

        <div className='container-fluid' translate='no'>
          <div className='row d-md-block d-lg-block d-xl-flex justify-content-center p-2 p-md-5 p-xl-5 mx-2 mt-5 pt-5'>
          <div className='col-8 col-md-12 col-lg-12 col-xl-3 services-text px-lg-4 width-fijo'>
            <p>Trabajamos para individuos, constructoras, estudios y empresas</p>
          </div>
          <div className="d-none d-sm-none d-md-none d-xl-block width-100">
            <div className="services-line"></div>
          </div>
          <div className='col-8 col-md-12 col-lg-12 col-xl-3 services-text px-lg-4 px-xl-5 width-fijo'>
            <p>Atendemos a nuestros clientes de manera personal y eficiente</p>
          </div>
          <div className="d-none d-sm-none d-md-none d-xl-block width-100">
            <div className="services-line"></div>
          </div>
          <div className='col-8 col-md-12 col-lg-12 col-xl-3 services-text width-fijo'>
            <p>Amoblamientos a medida y medidas estandar para mayoristas</p>
          </div>
          </div>
        </div>
      </section>
    </div>
  </>);
}

export default Services;
