import React from 'react';
import Hero from './Hero';
import "./home.css";
import Services from './Services';
import Portada from './Portada';
import MediosDePago from './MediosDePago';


import portada from '../assets/background-img.png'


const Home = () => {
  return (
    <>
      <Hero
        cName="hero"
        heroImg={portada}
        text="Nuestros muebles son la armonía perfecta para tu hogar. "
      />
      <Services />
      <Portada />
      <MediosDePago />

    </>
  );
}

export default Home;

