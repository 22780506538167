import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
import Contact from './components/Contact';
import ProductCategory from './components/ProductCategory';
import Footer from './components/Footer';
import Whatsapp from './components/Whatsapp'
import Nosotros from './components/Nosotros';

const App = () => {

  return (
    <Router>
      <Whatsapp />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/productos/:category/:subcategory?/:subsubcategory?" element={<ProductCategory />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
