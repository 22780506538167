const ProductCategoryTitle = {
    brillantes: 'Laqueado brillante',
    satinados: 'Laqueado satinado',
    melamina: 'Melamina',
    vintage: 'Vintage',
    placares:'Placares',
    vestidores:'Vestidores',
    tv: 'TV',
    escritorios: 'Escritorios',
    correderas: 'Correderas de cajones',
    bisagras: 'Bisagras',
    kitPuertas: 'Kit de puertas corredizas',
    tapaCantos: 'Tapa cantos PVC y ABS'
};

export default ProductCategoryTitle;