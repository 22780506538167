import React, { useRef, useState } from 'react';
import './contact.css'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

const Contact = () => {

  const captcha = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onChange = () => {
    if (captcha.current.getValue()) {
      console.log('El usuario no es un robot');
      setCaptchaVerified(true);
    }
  }

  const submit = (e) => {
    e.preventDefault();

    if (captchaVerified) {
      console.log('Enviando formulario:', e.target.action);
      e.target.submit();
    } else {
      Swal.fire({
      text: "Por favor, aceptá el captcha para enviar el formulario",
      confirmButtonColor: '#121111'
    });
      
    }
  }

  return (
    <>
      <div className="container-fluid row text-white d-md-row d-lg-flex d-xl-flex justify-content-center" translate='no'>
        <div className='col-10 col-md-10 col-lg-5 col-xl-5 contact-mt contact-ml p-map'>
          <h2 className='contact-title mb-5'>¡Contactate con nosotros!</h2>
          <Form method="POST" action="contacto.php" onSubmit={submit}>
            <Row className='ps-0 p-form'>
              <Col xl={12}>
                <Form.Control type="text" placeholder="Nombre" name='nombre' />
              </Col>
              <Col xl={12}>
                <Form.Control placeholder="Email" type="email" name='email' />
              </Col>
              <Col xl={12}>
                <Form.Control placeholder="Teléfono" type="text" name='telefono' />
              </Col>
            </Row>
            <Form.Control as="textarea" placeholder="Mensaje" className='text-area' name='mensaje' rows={6} />
            <ReCAPTCHA 
            ref={captcha}
            sitekey="6Lche1UpAAAAAElyrehlSHpsbJx8tMTrZi07ZNxo"
            onChange={onChange} 
            />
            <Button variant="dark" className='col-12 contact-button mt-4' type="submit">ENVIAR</Button>
          </Form>
        </div>

        <div className='text-sm-center col-sm-12 col-md-10 col-lg-5 col-xl-5 mt-map text-center pt-3 p-map'>
          <iframe title='map' className='map-style' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6565.543967650816!2d-58.593034!3d-34.635202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb8792267bb4b%3A0x1c996335d7f17806!2sAv.%20Pres.%20Per%C3%B3n%202101%2C%20B1706%20El%20Palomar%2C%20Provincia%20de%20Buenos%20Aires%2C%20Argentina!5e0!3m2!1ses-419!2sus!4v1702859505520!5m2!1ses-419!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <p className='contact-text-2 col-sm-12 col-md-12 col-lg-12 col-xl-11'>Av Presidente Peron 2101 <br />
            Palomar, Prov de Buenos Aires <br />
            info@atumedida.com.ar <br />
            <a href='https://api.whatsapp.com/send?phone=541163003824&text=Hola%20A%20Tu%20Medida!...' target='blank' className='a-no-deco'> Diseño y Cotización | 11 63003824</a> <br />
            <a href='https://api.whatsapp.com/send?phone=541127017384&text=Hola%20A%20Tu%20Medida!...' target='blank' className='a-no-deco'> Diseño y Cotización | 11 27017384</a> <br />
            <a href='https://api.whatsapp.com/send?phone=541123501739&text=Hola%20A%20Tu%20Medida!...' target='blank' className='a-no-deco'> Fábrica | 11 23501739</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Contact;
